import React from 'react'
import HamburgerIcon from './hamburger'
import LogoMark from './logoMark'

function Icon (props) {
  switch (props.symbol) {
    case 'hamburger':
      return <HamburgerIcon />
    case 'logoMark':
      return <LogoMark />
    default:
      return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
